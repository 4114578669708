import React, { useEffect } from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import { MYACCOUNT_LIGHT } from "../../../constants/urls";
import { useLocation } from "@reach/router";
// MUI imports
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import { Link } from 'gatsby';
// Icons
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// Components
import StyledDialogTitle from './title';
import StyledDialogContent from './contentSignupSocial';
import Logo from '../Logo/LogoLogin';

import defaults from './defaults';

// My Account
import { useAuthState } from '../../../services';
import { loginRedirect } from '../user/authcallback'
import { useQuery } from '../utils';

const SignUpModal = withSubtheme(props => {
    const {
        theme,
        className,
        dialogTitleProps,
        dialogContentProps,
        showMarketingConsent,
        login,
        signup,
        logoType
    } = props

    const {
        signupBtnType,
        signupBtnVariant,
        fullScreen,
        dialogScroll,
        data,
        defaultCountry,
        usePhoneField
    } = signup

    const {
        setOpenDefault,
        triggerText,
        signUpTitle,
        signUpSubtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        formActionLabel,
    } = data

    const {
        termsSiteLink,
        termsThemeLabel,
        privacySiteLink,
        privacyThemeLabel
    } = theme;


    // get location
    const location = useLocation();
    const error = useQuery(location, 'error');

    const { state, services } = useAuthState();

    const hasPendingData = (user) => {
        if (!user) return true
        return (
            (!(user.name || user.forname)) || 
            !user.surname || 
            !user.email || 
            (usePhoneField ? !user.mobile : false) ||
            (user.newsAndUpdatesSubscription !== true && user.newsAndUpdatesSubscription !== false)
        )
    }

    let signupSubtitleSub = null;
    if (undefined !== login.data.loginUrl && undefined !== signUpSubtitle)
        signupSubtitleSub = <>{signUpSubtitle} <Link to={login.data.loginUrl} className="link-login">{login.data.loginUrlLabel}</Link></>;

    // logic for opening/closing dialog
    const [open, setOpen] = React.useState(setOpenDefault);
    const fullScreenBreakpoint = fullScreen ? useMediaQuery(theme.breakpoints.down(fullScreen)) : false;
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        if (true === setOpenDefault) {
            return null
        } else {
            setOpen(false);
        }
    };

    // Reset error message
    useEffect(() => {
        services.resetMessage();
    }, []);

    useEffect(() => {
            services.providerAuthLogin(props.provider, props.location.search)
    }, [props.location.search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if((MYACCOUNT_LIGHT || !hasPendingData(state.user)) && !state.user?.blocked && state.user?.confirmed) {
            loginRedirect(services, state)
        }
    }, [state.user]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!error && (!state.user || MYACCOUNT_LIGHT || !hasPendingData(state.user)))
        return <p>Please Wait...</p>

    let loginTypeOut = null;
    if (!setOpenDefault) { // if we want the modal to be always opened we don't need a trigger button
        switch (signupBtnType) {
            case "btn":
                loginTypeOut = <Button variant={signupBtnVariant} color="primary" onClick={handleClickOpen}>{triggerText}</Button>
                break;
            case "btnIcon":
                loginTypeOut = <IconButton aria-label="signup" color="inherit" variant={signupBtnVariant} onClick={handleClickOpen}><VpnKeyIcon /></IconButton>
                break;
            case "btnTextIcon":
                loginTypeOut = (<Button
                                    variant={signupBtnVariant}
                                    color="primary"
                                    startIcon={<VpnKeyIcon />}
                                    onClick={handleClickOpen}
                                >
                                    {triggerText}
                                </Button>)
                break;
            default:
                loginTypeOut = <Button color="primary" variant={signupBtnVariant} onClick={handleClickOpen}>{triggerText}</Button>
                break;
        }
    }

    return (
        <>
            {loginTypeOut}
            <Dialog
                fullScreen={fullScreenBreakpoint}
                open={open}
                onClose={handleClose}
                scroll={dialogScroll}
                aria-labelledby="signup-dialog"
                classes={{root: className, paper: "paper-dialog"}}
                BackdropProps={{ classes: {root: "backDrop"} }}
            >
                <Logo type1={logoType || 'logo'} />
                {!error && (
                    <StyledDialogTitle
                        id="signup-responsive-dialog-title"
                        title={`Please verify and update your details`}
                        dialogTitleProps={dialogTitleProps}
                    />
                )}

                <StyledDialogContent
                    showMarketingConsent={showMarketingConsent}
                    subtitle={signupSubtitleSub}
                    showSocialLogin={showSocialLogin}
                    showTerms={showTerms}
                    termsText={termsText}
                    termsLinkProps={termsLinkProps}
                    termsLink={termsSiteLink || termsLink}
                    termsLabel={termsThemeLabel || termsLabel}
                    privacyLink={privacySiteLink || privacyLink}
                    privacyLabel={privacyThemeLabel || privacyLabel}
                    handleClose={handleClose}
                    defaultCountry={defaultCountry}
                    usePhoneField={usePhoneField}
                    state={state}
                    services={services}
                    formActionLabel={formActionLabel}
                    dialogContentProps={dialogContentProps}
                    error={error}
                />
            </Dialog>
        </>
    );
}, 'loginSignup', defaults)

export default React.memo(SignUpModal);
